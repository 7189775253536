<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="basket"
          class="elevation-1"
          item-key="id"
          :loading="loading"
          disable-pagination
          hide-default-footer
        >
          <template #item.evaluation="{ item }">
            <template v-if="item.evaluation_start && item.evaluation_end">
              <v-row justify="center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        medium
                        :elevation="hover ? 4 : 0"
                        :color="hover ? 'success' : 'white'"
                        :class="{ 'on-hover': hover }"
                        v-bind="attrs"
                        v-on="on"
                        @click="setDatesBtn(item)"
                      >
                        <span
                          >{{ moment(item.evaluation_start).format("lll") }}
                        </span>
                        <v-icon>mdi-minus</v-icon>
                        <span
                          >{{ moment(item.evaluation_end).format("lll") }}
                        </span>
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>Click To Edit Deadlines</span>
                </v-tooltip>
              </v-row>
            </template>
            <template v-else>
              <v-btn color="success" icon large @click="setDatesBtn(item)">
                <v-icon large>mdi-calendar-blank-multiple</v-icon>
              </v-btn>
            </template>
          </template>
          <!-- <template #item.extension="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  large
                  dark
                  v-bind="attrs"
                  @click="showDataExtension(item)"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-36px mdi-dots-horizontal-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Date Extension Management</span>
            </v-tooltip>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>

    <v-navigation-drawer
      clipped
      overlay-color="black"
      v-model="eDate"
      fixed
      :permanent="eDate"
      right
      width="40%"
      class="shadow"
    >
      <v-overlay absolute :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card flat class="mt-16">
        <v-toolbar tile color="primary" dark flat>
          <b class="text-uppercase">Header Deadlines </b>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            @click="
              eDate = false;
              dates = [];
            "
            icon
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text v-if="!response">
          <v-row>
            <v-col>
              <v-date-picker
                elevation="2"
                v-model="dates"
                range
                full-width
                landscape
              ></v-date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-sheet outlined class="pa-4 text-h5">
                <span v-if="dates.length == 2">
                  <template v-if="moment(dates[1]).isAfter(dates[0])">
                    <v-icon x-large> mdi-calendar </v-icon>
                    {{ `From  ${computedDates}` }}
                  </template>
                  <v-alert
                    v-if="moment(dates[1]).isBefore(dates[0])"
                    type="error"
                  >
                    {{
                      `End Date ${dates[1]} Should Be Greater Than Start Date ${dates[0]}`
                    }}
                  </v-alert>
                </span>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <template v-if="!success">
            <span class="text-h5 text-wrap">
              <v-alert text type="error">
                Something Went Wrong. Try Again Later Or Call The System
                Administrator
              </v-alert>
            </span>
          </template>
          <template v-else>
            <span class="text-h5 text-wrap">
              <v-alert text type="success">
                Header Deadlines Are Updated/Saved Successfully...
              </v-alert>
            </span>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-footer color="transparent" app>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!response && !success"
              @click="setElevationDatesBtn"
              outlined
              color="primary"
              class="mr-2 font-weight-bold"
              :disabled="
                dates.length <= 1 || moment(dates[1]).isBefore(dates[0])
              "
            >
              set dates
            </v-btn>
            <v-btn color="grey" dark v-if="response" @click="eDate = false">
              Close
            </v-btn>
          </v-footer>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { computed, defineComponent, getCurrentInstance, provide, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import moment from "moment";
  import Response from "@/components/ActionResponse/Response";

  export default defineComponent({
    components: { Response },
    setup() {
      const vm = getCurrentInstance()
      const { getHeaders, setElevationDates, signOut } = useActions([
        "getHeaders",
        "setElevationDates",
        "signOut",
      ]);
      const { getters_headers } = useGetters(["getters_headers"]);

      const data = reactive({
        dates: [],
        eDate: false,
        headers: [
          { text: "No.", value: "index" },
          { text: "CALENDAR", value: "calendar.academic_calendar" },
          { text: "PROGRAM", value: "program.program_name_certificate" },
          { text: "LEVEL", value: "level.level" },
          { text: "INTAKE", value: "intake.intake" },
          { text: "SEMESTER", value: "semester.semester" },
          {
            text: "EVALUATION DATES",
            value: "evaluation",
            sortable: false,
            align: "center",
          },
        ],
        loading: true,
        drawer: false,
        selectedHeader: 0,
        showExtension: false,
        editExtensionDates: {},
        startDate: null,
        endDate: null,
        success: false,
        response: false,
        overlay: false,
        color: null,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
      });

      const {
        overlay,
        loading,
        eDate,
        dates,
        selectedHeader,
        showExtension,
        editExtensionDates,
        startDate,
        endDate,
        response,
        success,
        color,
        msgHeader,
        msgBody,
        msgIcon,
      } = toRefs(data);

      getHeaders().then(() => {
        loading.value = false;
      });

      const basket = computed(() =>
        getters_headers.value.map((item, index) => ({
          ...item,
          index: index + 1,
        }))
      );

      const setDatesBtn = (item) => {
        eDate.value = true;
        response.value = false;
        success.value = false;
        selectedHeader.value = item.id;

        startDate.value = moment(item.evaluation_start).format("YYYY-MM-DD");
        endDate.value = moment(item.evaluation_end).format("YYYY-MM-DD");

        dates.value.push(startDate.value, endDate.value);
      };

      const computedDates = computed(() => dates.value.join(" To "));

      const setElevationDatesBtn = () => {
        overlay.value = true;

        setElevationDates({
          id: selectedHeader.value,
          evaluation_start: new Date(`${dates.value[0]}T00:00:00Z`),
          evaluation_end: new Date(`${dates.value[1]}T23:59:59Z`),
        })
          .then(() => {
            success.value = true;
            msgHeader.value = "Success";
            msgBody.value = "Saved successfully";
            msgIcon.value = "mdi-check-circle";
            color.value = "success";
          })
          .catch(() => {
            success.value = false;
          })
          .catch((e) => {
            response.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 403:
              case 401:
                msgBody.value = e.response.data.message;
                break;
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              default:
                msgBody.value =
                  "Try Again Later or Call The System Administrator";
                break;
            }
          })
          .finally(() => {
            dates.value = [];
            response.value = true;
            overlay.value = false;
          });
      };

      const showDataExtension = (template) => {
        editExtensionDates.value = template;
        showExtension.value = true;
      };

      provide("editExtensionDates", editExtensionDates);
      provide("color", color);

      return {
        ...toRefs(data),
        basket,
        setDatesBtn,
        setElevationDatesBtn,
        computedDates,
        showDataExtension,
      };
    },
  });
</script>
